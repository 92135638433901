<template>
    <div class="container">
        <!-- 公用头部 -->
        <!-- <HeaderView></HeaderView> -->
        <!-- 内容 -->
        <router-view></router-view>
        <!-- 公用尾部 -->
        <FooterView></FooterView>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/headerView.vue'
import FooterView from '@/components/footerView.vue'
export default {
  name: 'Home',
  components: {
    HeaderView,
    FooterView
  },
}
</script>
<style lang="less">
.container{
  // 出现滚动条时，背景图铺完
  min-width: max-content;
  min-width: -moz-max-content;
}
</style>
