<template>
  <div class="container">
    <div class="foot_content">
      <div class="foot_left">
        <img src="../assets/images/footLogo.png" alt="">
      </div>
      <ul>
        <li>
          <span @click="goPage('hecp')" class="activeRoute">核心产品</span>
          <span @click="goPage('hzst')" class="activeRoute">合作生态</span>
          <span @click="goPage('hydt')" class="activeRoute">行业动态</span>
        </li>
        <li>
          <span>关于我们</span>
          <span @click="goPage('gsjj')" class="activeRoute">公司简介</span>
          <span @click="goPage('jrwm')" class="activeRoute">加入我们</span>
        </li>
        <li>
          <span>联系我们</span>
          <span>电话：15003991095</span>
          <span>地址：河南省郑东新区电子商务大厦B座14-15层</span>
        </li>
      </ul>
      <div class="qr">
        <img src="../assets/images/qr.jpeg" alt="">
        <span>扫一扫关注我们</span>
      </div>
    </div>
      <p><span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="__black">备案号：{{beian}}</a></span><span>Data solutions 2018 - 2023 ©</span></p>
  </div>
</template>

<script>
import { getConfigInfo } from '@/request/api'
export default {
  name: 'footerView',
  data(){
    return{
      beian:'',
    }
  },
  created(){
    this.getConfigInfo()
  },
  methods:{
    goPage(type){
      switch(type){
        case 'hecp':
          this.$router.push("/coreProducts");
          break;
        case 'hzst':
          this.$router.push("/ecology");
          break;
        case 'hydt':
          this.$router.push("/news");
          break;
        case 'gsjj':
          this.$router.push("/aboutUs");
          break;
        case 'jrwm':
          this.$router.push("/contact");
          break;
      }
    },
    getConfigInfo(){
      getConfigInfo().then((res)=>{
        // console.log('站点信息',res)
        this.beian = res.data.beian
      })
    }
  }
}
</script>

<style scoped lang="less">
.container{
  width:1920px;
  height:243px;
  background:#08163E;
  position:relative;
  .foot_content{
    width:1100px;
    margin:0 auto;
    color:#fff;
    font-size:16px;
    display:flex;
    align-items:center;
    align-content:center;
    justify-content: space-between;
    ul{
      padding-top:52px;
      display:flex;
      margin-left:50px;
      li{
        height:100px;
        margin:0 20px;
        span{
          display:block;
          margin-bottom:12px;
          text-align:left;
          &:nth-of-type(1){
            margin-bottom:20px;
          }
        }
        .activeRoute{
          cursor: pointer;
          &:hover{
            color:#E73921;
          }
        }
      }
    }
    .qr{
      display: flex;
      flex-direction: column;
      align-items:center;
      color: #999999;
      font-size:14px;
      margin-top:52px;
      img{
        width:85px;
        margin-bottom:14px;
      }
    }
    .foot_left{
      width:122px;
      height:80px; 
      margin-top:90px;
      img{
        width:100%;
      }
    }
  }
  p{
    width:100%;
    position:absolute;
    left:0;
    bottom:0;
    height:40px;
    line-height:40px;
    color:#fff;
    text-align:center;
    font-size:12px;
    border-top:1px solid #656565;
    span{
      margin:0 15px;
      a{
        color:#fff;
        text-decoration: none;
        cursor: pointer;
        &:hover{
          color:#E73921;
        }
      }
    }
  }
}
</style>
